<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!--<div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        :to="{ name: '' }"
        :disabled="uploading"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div> --->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-container
      class="container-small d-flex flex-column justify-center align-center"
      style="margin-top: 5%"
    >
      <div>
        <h1
          class="text-center mb-2"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Porte-monnaie en ligne
        </h1>
      </div>
      <div class="d-flex flex-row align-center">
        <v-form @submit.prevent="validerMontant">
          <div style="margin-top: 10px; margin-bottom: 30px">
            <div
              class="subtitle-1 text-center"
              :style="{
                fontWeight: 400,
                fontFamily: WEBAPPPRO_FONT_FAMILY_2,
                fontSize: WEBAPPPRO_FONT_SIZE_2,
                lineHeight: '20px',
                textAlign: 'center',
                // color: WEBAPPPRO_TEXT_COLOR_2,
              }"
            >
              <p>
                Je crédite mon porte-monnaie en ligne.<br />(montant minimum :
                50€)
              </p>
              <!--<p>
            Le prix d'achat d'un code Ephoto est de {{ ephotoPrice }} €, et le
            montant minimum de recharge est de
            {{ Math.ceil(ephotoPrice * 10) }} €.
          </p>-->
            </div>
          </div>
          <v-row class="d-flex justify-space-between">
            <v-text-field
              v-model="montant"
              type="number"
              required
              solo
              class="ma-2"
              :class="{ 'focused-border': isFocused }"
              :style="{
                borderRadius: '8px',
                width: '400px',
                height: '52px',
                marginRight: '30px',
                marginTop: '30px',
              }"
              @focus="handleFocus('montant')"
              @blur="handleBlur('montant')"
            >
              <template v-slot:prepend-inner>
                <svg
                  :class="{ 'focused-icon': isFocused.montant }"
                  style="margin: 10px"
                  width="17"
                  height="13"
                  viewBox="0 0 17 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3391 3.2048H14.212V2.53621C14.212 1.13763 13.0202 0 11.5528 0H2.21716C0.994947 -1.70559e-08 0 0.948307 0 2.11493V10.4638C0 11.8624 1.19359 13 2.66095 13H14.3391C15.8064 13 17 11.8624 17 10.4638V5.74101C17 4.34242 15.8064 3.2048 14.3391 3.2048ZM2.21716 1.02335H11.5528C12.4279 1.02335 13.1383 1.70218 13.1383 2.53621V3.2048H2.21716C1.91116 3.2048 1.62485 3.09224 1.40831 2.88585C1.1918 2.68118 1.07368 2.40658 1.07368 2.11493C1.07368 1.51286 1.58726 1.02335 2.21716 1.02335ZM15.9263 9.23576H14.7381C14.0832 9.23576 13.5499 8.7275 13.5499 8.10155C13.5499 7.4773 14.0832 6.96904 14.7381 6.96904H15.9263V9.23576ZM15.9263 5.94568H14.7381C13.4908 5.94568 12.4762 6.91275 12.4762 8.10155C12.4762 9.29205 13.4908 10.2591 14.7381 10.2591H15.9263V10.4638C15.9263 11.2978 15.2141 11.9766 14.3391 11.9766H2.66095C1.7859 11.9766 1.07368 11.2978 1.07368 10.4638V3.92797C1.41548 4.12411 1.80738 4.22815 2.21716 4.22815H14.3391C15.2141 4.22815 15.9263 4.90697 15.9263 5.74101V5.94568Z"
                    fill="#C1C1C1"
                  />
                </svg>
              </template>
              <template v-slot:label>
                <span class="text-align-left" style="color: #c1c1c1"
                  >Montant en euro</span
                >
              </template>
            </v-text-field>
          </v-row>
          <div class="d-flex flex-row justify-space-between mt-8">
            <div class="d-flex flex-column">
              <v-btn
                :color="WEBAPPPRO_BUTTON_COLOR_2"
                v-if="goBack"
                @click="goBackAction()"
                block
                :style="{
                  color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
                  boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                  borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                  textTransform: 'none',
                  marginBottom: '10px',
                  marginTop: '10px',
                  padding: '0px',
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                  fontWeight: 'bold',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  width: '240px',
                  height: '52px',
                }"
              >
                J’annule
              </v-btn>
            </div>
            <div class="d-flex flex-column ml-4">
              <v-btn
                :color="WEBAPPPRO_BUTTON_COLOR_1"
                @click="validerMontant"
                :loading="loading"
                block
                :style="{
                  color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
                  boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                  borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                  textTransform: 'none',
                  marginBottom: '10px',
                  marginTop: '10px',
                  padding: '0px',
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                  fontWeight: 'bold',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  width: '240px',
                  height: '52px',
                }"
              >
                Je valide
              </v-btn>
            </div>
          </div>
        </v-form>
        <svg
          v-if="getTechnicalInfoBoolean('SEPA_DEBIT_ALLOWED')"
          style="margin-left: 100px; margin-right: 100px"
          width="2"
          height="180"
          viewBox="0 0 2 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 0V180" stroke="#C1C1C1" stroke-width="2" />
        </svg>
        <div
          style="margin-top: 60px; margin-bottom: 30px"
          v-if="getTechnicalInfoBoolean('SEPA_DEBIT_ALLOWED')"
        >
          <div
            class="subtitle-1 text-center"
            :style="{
              fontWeight: 400,
              fontFamily: WEBAPPPRO_FONT_FAMILY_2,
              fontSize: WEBAPPPRO_FONT_SIZE_2,
              lineHeight: '20px',
              textAlign: 'center',
            }"
          >
            <p>
              Le prélèvement SEPA xxxx xxxxxx xx xxxxx xxxxxxx xx<span
                style="margin-left: 10px"
              >
                <span
                  v-if="getTechnicalInfoBoolean('SEPA_DEBIT_ACTIVATED')"
                  class="sepa_status"
                  :style="{
                    display: 'inline-block',
                    backgroundColor: 'green',
                    color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
                    boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                    padding: '5px 10px',
                    borderRadius: '4px',
                    verticalAlign: 'middle',
                  }"
                >
                  Activé
                </span>
                <span
                  v-else-if="getTechnicalInfoString('SEPA_DEBIT_ACTIVATION')"
                  class="sepa_status"
                  :style="{
                    display: 'inline-block',
                    backgroundColor:
                      getTechnicalInfoString('SEPA_DEBIT_ACTIVATION') ==
                      'IN_PROGRESS'
                        ? 'orange'
                        : 'red',
                    color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
                    boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                    padding: '5px 10px',
                    borderRadius: '4px',
                    verticalAlign: 'middle',
                  }"
                >
                  {{
                    getTechnicalInfoString('SEPA_DEBIT_ACTIVATION') ==
                    'IN_PROGRESS'
                      ? 'En cours'
                      : 'Echoué'
                  }}
                </span>
              </span>
            </p>
            <p>
              Je simplifie mes paiements et j'opte pour le prélèvement SEPA.
              <br />Je serai débité uniquement à la fin du mois, en fonction de
              ma consommation.
            </p>
          </div>
          <v-btn
            v-if="!getTechnicalInfoBoolean('SEPA_DEBIT_ACTIVATED')"
            :color="WEBAPPPRO_BUTTON_COLOR_2"
            @click="activate()"
            block
            :style="{
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
              boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
              borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
              textTransform: 'none',
              marginBottom: '10px',
              marginTop: '10px',
              padding: '0px',
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              width: '240px',
              height: '52px',
            }"
          >
            Activer le prélèvement SEPA
          </v-btn>
          <div
            v-if="getTechnicalInfoBoolean('SEPA_DEBIT_ACTIVATED')"
            class="d-flex flex-column"
            align="center"
          >
            <v-btn
              :color="WEBAPPPRO_BUTTON_COLOR_2"
              v-if="
                credit > 0 && getTechnicalInfoBoolean('SEPA_DEBIT_ACTIVATED')
              "
              @click="cancelSepaDebit()"
              block
              :style="{
                color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
                boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                textTransform: 'none',
                marginBottom: '10px',
                marginTop: '10px',
                padding: '0px',
                fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                fontWeight: 'bold',
                lineHeight: '18px',
                letterSpacing: '0em',
                textAlign: 'center',
                width: '240px',
                height: '52px',
              }"
            >
              Annuler le prélèvement SEPA
            </v-btn>
            <v-btn
              :color="WEBAPPPRO_BUTTON_COLOR_2"
              v-if="getTechnicalInfoBoolean('SEPA_DEBIT_ACTIVATED')"
              @click="activate()"
              block
              :style="{
                color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
                boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                textTransform: 'none',
                marginBottom: '10px',
                marginTop: '10px',
                padding: '0px',
                fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                fontWeight: 'bold',
                lineHeight: '18px',
                letterSpacing: '0em',
                textAlign: 'center',
                width: '240px',
                height: '52px',
              }"
            >
              Modifier mon RIB
            </v-btn>
          </div>
        </div>
      </div>
      <v-alert
        v-if="montantValide !== null"
        :type="montantValide ? 'success' : 'error'"
        outlined
        class="mt-4"
      >
        <p v-if="montantValide">Montant valide : {{ montant }} €</p>
        <p v-else>
          Montant invalide. Veuillez entrer un montant valide (minimum
          {{ Math.ceil(this.ephotoPrice * 10) }} €).
        </p>
      </v-alert>
      <v-card
        class="pa-6"
        :style="{
          borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
          marginTop: '20px',
          width: '755px',
        }"
      >
        <v-data-table
          class="custom-data-table"
          :headers="headers"
          :items="
            tableData.map(item => ({
              ...item,
              createdAt: formatCreatedAt(item.createdAt),
              montantAvecDevise: `${item.amount} ${item.currency}`,
              createdAtSortable: new Date(
                item.createdAt.split('/').reverse().join('-'),
              ).getTime(),
            }))
          "
          :items-per-page="10"
          sort-by="createdAtSortable"
          :sort-desc="true"
        >
          <!-- <v-data-table
          class="custom-data-table"
          :headers="headers"
          :items="items"
          :items-per-page="10"
        > -->
          <template v-slot:[`item.createdAt`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.createdAt }}
            </td>
          </template>
          <template v-slot:[`item.montantAvecDevise`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.montantAvecDevise }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td>
              <v-icon @click="telechargerFacture(item.id)"
                >mdi-file-download</v-icon
              >
              <!-- <v-btn
                :color="WEBAPPPRO_BUTTON_COLOR_1"
                
                :loading="loading"
                block
                :style="{
                  color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
                  boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
                  borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
                  textTransform: 'none',
                  marginBottom: '10px',
                  marginTop: '10px',
                  padding: '0px',
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
                  fontWeight: 'bold',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  width: '240px',
                  height: '52px',
                }"
              >
                Télécharger la facture
              </v-btn> -->
            </td>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <td class="bold-text" :style="{ fontSize: WEBAPPPRO_FONT_SIZE_3 }">
              {{ item.id }}
            </td>
          </template>
        </v-data-table>
      </v-card>
      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500px">
            <v-card width="auto">
              <div style="text-align: right">
                <v-icon right size="large" @click="dialog = false"
                  >mdi-close-circle</v-icon
                >
              </div>
              <v-col>
                <v-card class="" elevation="0">
                  <stripe-element-payment
                    ref="paymentRef"
                    :pk="pk"
                    :elements-options="elementsOptions"
                    :confirm-params="confirmParams"
                    :locale="language"
                  />
                  <v-divider
                    inset
                    color="#212121"
                    style="margin: 20px"
                  ></v-divider>
                  <v-container
                    class="bg-surface-variant"
                    style="margin-top: 20px"
                  >
                    <v-row>
                      <v-col class="">
                        <v-sheet
                          class="d-flex flex-row align-center justify-end"
                        >
                          <v-btn
                            @click="pay"
                            rounded
                            class="ml-10"
                            style="
                              background-color: #000000;
                              color: white;
                              font-family: Georama;
                              text-transform: none;
                            "
                            variant="text"
                          >
                            Confirmer
                          </v-btn>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import Accueil from '../../components/Accueil.vue'
import { refreshToken } from './RefreshToken.vue'
export default {
  name: 'Wallet',
  components: {
    StripeElementPayment,
    Accueil,
  },
  data() {
    return {
      montant: null,
      realAmount: null,
      loading: false,
      montantValide: null,
      dialog: false,
      language: 'fr',
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_4',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      items: [],
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
      WEBAPPPRO_LOGO_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_1',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_CARTE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARTE',
      ),
      WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_COLOR',
      ),
      WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1_FILL_ICON',
      ),
      WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_ETAPES',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_SEPA_DEBIT_ALLOWED: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_SEPA_DEBIT_ALLOWED',
      ),
      WEBAPPPRO_SEPA_DEBIT_ACTIVATED: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_SEPA_DEBIT_ACTIVATED',
      ),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      headers: [
        { text: 'Date de création', value: 'createdAt' },
        { text: 'Numéro de facture', value: 'id' },
        { text: 'Montant', value: 'montantAvecDevise' },
        { text: '', value: 'actions' },
      ],
      walletPrice: null,
      credit: null,
      goBack: true,
      pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {},
      tableData: [],
      isFocused: {
        //email: false,
        montant: false,
      },
      technicalInformations: [],
    }
  },
  methods: {
    activate() {
      this.$router.push({
        name: 'Sepa',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    getTechnicalInfoBoolean(key) {
      return (
        this.technicalInformations &&
        this.technicalInformations[key] &&
        (this.technicalInformations[key] === 'true' ||
          !!this.technicalInformations[key])
      )
    },
    getTechnicalInfoString(key) {
      return this.technicalInformations[key]
    },
    async handleRefreshToken() {
      try {
        await refreshToken()
      } catch (error) {
        console.error('Erreur lors du rafraîchissement du token:', error)
        // Gérez les erreurs spécifiques ici si nécessaire
      }
    },
    telechargerFacture(id) {
      this.$store.state.loading = true

      const token = localStorage.getItem('token')

      requestService
        .get(`/order/${id}/download/invoice`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          this.downloadBase64File(
            response.data.data,
            'application/pdf',
            response.data.fileName,
          )
          this.$store.state.loading = false
          this.$store.dispatch(
            'alert/success',
            'Invoice downloaded successfully!',
          )
        })
        .catch(error => {
          console.error('Erreur lors du téléchargement de la facture', error)
          this.$store.state.loading = false
          this.$store.dispatch('alert/error', 'Invoice download failed!')
        })
    },

    downloadBase64File(base64Data, contentType, fileName) {
      const linkSource = `data:${contentType};base64,${base64Data}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
    handleFocus(field) {
      this.isFocused[field] = true
    },
    handleBlur(field) {
      this.isFocused[field] = false
    },
    validerMontant() {
      if (
        this.ephotoPrice > 0 &&
        this.montant !== null &&
        !isNaN(this.montant) &&
        this.montant >= Math.ceil(this.ephotoPrice * 10)
      ) {
        requestService
          .post('/order/', {
            partnerOwnerUid: this.$route.params.partner_uid,
            orderItems: [
              {
                quantity: 1,
                amount: this.montant,
                currency: 'EUR',
                priceId: this.walletPrice,
              },
            ],
          })
          .then(data => {
            if (data.data.amount == 0) {
              this.chargerDonnees()
            } else {
              localStorageService.setObject('orderId', data.data.id)
              localStorageService.setObject(
                'checkoutSessionId',
                data.data.checkoutSessionId,
              )
              this.elementsOptions.clientSecret = data.data.stripe_client_secret
              this.confirmParams.return_url =
                process.env.VUE_APP_WEB_APP +
                '/wallet-agence/' +
                this.$route.params.partner_uid
              this.dialog = true
            }
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors pendant l'achat de crédit.",
            )
          })
      } else {
        this.montantValide = false
      }
    },
    // Method to initiate payment
    pay() {
      this.$refs.paymentRef.submit() // Submit the payment form for payment processing
    },
    chargerDonnees() {
      this.dialog = false
      requestService
        .get('/wallet/wallet-product-type/' + this.$route.params.partner_uid)
        .then(response => {
          this.tableData = response.data.orders
          this.walletPrice = response.data.walletPrice
          this.ephotoPrice = response.data.unitPrice
          this.technicalInformations = response.data.technicalInformations
          this.credit = response.data.credit
        })
        .catch(error => {
          console.error('Erreur lors du chargement des données :', error)
        })
    },
    cancelSepaDebit() {
      requestService.get('/order/unactive-sepa').then(() => {
        this.chargerDonnees()
      })
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    formatCreatedAt(createdAt) {
      const date = new Date(createdAt)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('fr-FR', options)
    },
  },
  mounted() {
    this.chargerDonnees()
    // Appeler la méthode handleRefreshToken lors du montage du composant
    this.handleRefreshToken()
  },
  activated() {
    this.chargerDonnees()
  },
}
</script>

<style scoped>
.accueil-drawer {
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.custom-data-table .v-data-table__cell {
  font-weight: 500;
  font-size: var(--WEBAPPPRO_FONT_SIZE_3);
}
.focused-border {
  border-color: #47d881 !important;
}
.focused-icon path {
  fill: #47d881 !important;
}
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
  padding: 20px;
}
.sepa_status {
  text-transform: none;
  margin: 10px 0;
  padding: 0;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  width: 85px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Ajoutez des coins arrondis si nécessaire */
}
</style>
